export const lightPrimary = "#FAFAFA";

export const darkPrimary = "#363636";

export const darkSecondary = "#5c5c5c";

export const cuspotBlue = "#2050B2";

export const textDark = "#b0b3b8";

export const hoverGrey = "#3F3F3F";
